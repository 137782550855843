.react-datepicker__time-list-item--disabled {
  display: none;
}

.react-datepicker{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  border: none;
}

.react-datepicker__header {
  margin-left: auto;
  margin-right: auto;
  background-color: #064A72;
}

.react-datepicker__current-month {
  color: white;
}

.react-datepicker-time__header {
  color: white;
}
.react-datepicker__day-name {
  color: white;
}

.react-datepicker__month-container{
  overflow-x: auto;
  font-size: 17px;  
  margin: 0 auto;
  width: 80%;
}
.react-datepicker__time-container{
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  border: none;
  width: 80%;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 12%;
}

.react-datepicker__navigation--previous {
  left: 12%;
}

.react-datepicker__navigation {
  height: 50px;
}

.react-datepicker__navigation-icon {
}

.react-datepicker__month {
  margin: 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
overflow-y: auto;
font-size: 20px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100%;
  font-size: medium;
  padding: 0px;
  margin: 0px;
}
 


.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #064A72;
}

.react-datepicker__day--selected  {
  background-color: #064A72;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #064A72;
}


.react-datepicker__time-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.react-datepicker__time-list-item {
  display: inline-block;
  flex: 0 0 20%;
  margin: 0 1.5%;
  height: 30px;
  display: flex;
  justify-content: center;
  border: 1px solid #ccc;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease;

}

.react-datepicker__time-list-item:hover {
  background-color: #e5e5e5;
}

.react-datepicker__time-list-item--selected {
  background-color: #1e90ff;
  color: #fff;
}

.react-datepicker__time-list-item--disabled {
  display: none;
  opacity: 0.5;
  cursor: not-allowed;
}

@media only screen and (max-width: 720px) {

  .react-datepicker__time-list-item--disabled {
    display: none;
  }
  
  .react-datepicker{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    border: none;
  }
  
  .react-datepicker__header {
    margin-left: auto;
    margin-right: auto;
    background-color: #064A72;
  }
  
  .react-datepicker__current-month {
    color: white;
  }
  
  .react-datepicker-time__header {
    color: white;
  }
  .react-datepicker__day-name {
    color: white;
  }
  
  .react-datepicker__month-container{
    overflow-x: auto;
    font-size: 17px;  
    margin: 0 auto;
    width: 100%;
  }
  .react-datepicker__time-container{
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    border: none;
    width: 100%;
  }
  
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 12%;
  }
  
  .react-datepicker__navigation--previous {
    left: 12%;
  }
  
  .react-datepicker__navigation {
    height: 50px;
  }
  
  .react-datepicker__navigation-icon {
  }
  
  .react-datepicker__month {
    margin: 10px;
  }
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  overflow-y: auto;
  font-size: 20px;
  }
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100%;
    font-size: medium;
    padding: 0px;
    margin: 0px;
  }
   
  
  
  .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
    background-color: #064A72;
  }
  
  .react-datepicker__day--selected  {
    background-color: #064A72;
  }
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #064A72;
  }
  
}