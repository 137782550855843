.crud-table {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: rgb(6, 74, 114);
}
