.popup-show{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  width: 70vw;
  max-height: 90vh;
  overflow-y: auto;
}

.popup-hide {
  display: none;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.modal-show {
  display: block;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
};

.modal-hide {
  z-index: 0;
  display: none,
};

.rbc-day-bg {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
          flex: 1 0 0%;       
}

.rbc-toolbar {
  background-image: linear-gradient(#fec122, #fdc539);
  padding-top: 0px;
  padding-bottom: 0px;  
  margin-bottom: 0;
}

.rbc-toolbar button {
  color: #000000;
  background-color: #ffffff;
  border-color: #adadad;
}

.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-toolbar .rbc-toolbar-label {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 0 10px;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color: white;
}

.rbc-event-content {
  font-size: 14px;
}
