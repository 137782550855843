.not-found {
  height: 100vh;
  background-image: linear-gradient(to top, #fdd570, #fdce61, #fcc752, #fcc042, #fcb931);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.not-found h1 {
  font-size: 10rem;
  margin: 0;
  color: #fff;
}

.not-found h2 {
  font-size: 3rem;
  margin: 0;
  color: #fff;
}

.not-found p {
  font-size: 1.5rem;
  margin: 1rem 0 2rem;
  color: #fff;
}

.not-found a {
  font-size: 1.2rem;
  color: #fff;
  text-decoration: none;
  border: 1px solid #fff;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  transition: all 0.3s ease;
}

.not-found a:hover {
  background-color: #fff;
  color: #fcb931;
}