.RefreshButton {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RefreshButton:hover {
  background-color: #f5f5f5;
}

.RefreshButton:active {
  background-color: #eee;
}

.RefreshButton svg {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
